@import "~@fontsource/montserrat/400.css";
@import "~@fontsource/montserrat/700.css";

$font-family-base: 'Montserrat', sans-serif;
$primary: #f64442;

@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'TupperSerifMediumItalic';
    src: url('/fonts/TupperSerif_WOFF/TupperSerif-Mediumitalic.woff');
}

@font-face {
    font-family: 'TupperSerifBoldItalic';
    src: url('/fonts/TupperSerif_WOFF/TupperSerif-Bolditalic.woff');
}

h1 {
    font-family: 'TupperSerifMediumItalic', sans-serif;
    font-size: 2.5em;

    @media screen and (max-width: 800px) {
        font-size: 2.0em;
    }

    @media screen and (max-width: 480px) {
        font-size: 1.65em;
    }

    &.form-header.week2-AT {
        font-size: 2.25em;

        @media screen and (max-width: 800px) {
            font-size: 2.0em;
        }

        @media screen and (max-width: 480px) {
            font-size: 1.65em;
        }
    }

    &.comingsoon-BR {
        font-size: 2.15em;

        @media screen and (max-width: 800px) {
            font-size: 1.85em;
        }

        @media screen and (max-width: 480px) {
            font-size: 1.65em;
        }
    }
}

h2 {
    font-family: 'TupperSerifMediumItalic', sans-serif;
    font-size: 1.5em;

    strong {
        font-family: 'TupperSerifBoldItalic', sans-serif;
    }

    @media screen and (max-width: 800px) {
        font-size: 1.25em;

        br {
            display: none;
        }
    }

    @media screen and (max-width: 480px) {
        font-size: 1.10em;
    }
}

.btn-primary {
    color: #FFF;
    width: 100%;
    max-width: 400px;
    border-radius: 24px;

    &:hover {
        color: #FFF;
    }

    &:disabled, &.disabled {
        color: #FFF;
    }
}

.container {
    max-width: 800px;
}

header {
    #hero {
        width: 100%;
        max-width: 1000px;
        display: block;
        margin: 0 auto;
    }
}

footer {
    background: #000;
    @extend .text-muted;

    a {
        @extend .text-muted;
    }

    #logo {
        max-width: 200px;
    }
}

#challenges {
    width: 100%;
    max-width: 650px;
    display: block;
    margin: 0 auto;
}

#rules {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 2em 1em 0;

    h2 {
        font-family: "Montserrat", sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1em;
    }

    h3 {
        font-size: 1.30em;
        font-weight: 700;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
    }

    ol, ul {
        padding-left: 20px;

        &.lowerAlpha {
            list-style-type: lower-alpha;
        }
    }

    @media screen and (max-width: 480px) {
        padding-top: 1.5em;

        h2 {
            font-size: 1.25em;
            line-height: 1.4;
        }

        h3 {
            font-size: 1.10em;
        }

        p {
            font-size: 0.875em;
        }

        ol, ul {
            // font-size: 0.875em;
            padding-left: 16px;
        }

        a {
            word-break: break-word;
        }
    }
}
